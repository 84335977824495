import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const phoneService = service
  .enhanceEndpoints({ addTagTypes: ['Phone'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPhones: builder.query<Res['phones'], Req['getPhones']>({
        providesTags: (res, _, req) => [{ id: req.id, type: 'Phone' }],
        query: (query: Req['getPhones']) => ({
          url: `customers/companies/${query.id}/phones`,
        }),
      }),

      updatePhone: builder.mutation<Res['phone'], Req['updatePhone']>({
        invalidatesTags: (res, _, req) => [
          { id: req.company_id, type: 'Phone' },
        ],
        query: (query: Req['updatePhone']) => ({
          body: query,
          method: 'PUT',
          url: `customers/companies/${query.company_id}/phones/${query.phone_id}`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getPhones(
  store: any,
  data: Req['getPhones'],
  options?: Parameters<typeof phoneService.endpoints.getPhones.initiate>[1],
) {
  return store.dispatch(
    phoneService.endpoints.getPhones.initiate(data, options),
  )
}
export async function updatePhone(
  store: any,
  data: Req['updatePhone'],
  options?: Parameters<typeof phoneService.endpoints.updatePhone.initiate>[1],
) {
  return store.dispatch(
    phoneService.endpoints.updatePhone.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetPhonesQuery,
  useUpdatePhoneMutation,
  // END OF EXPORTS
} = phoneService

/* Usage examples:
const { data, isLoading } = useGetPhonesQuery({ id: 2 }, {}) //get hook
const [createPhones, { isLoading, data, isSuccess }] = useCreatePhonesMutation() //create hook
phoneService.endpoints.getPhones.select({id: 2})(store.getState()) //access data from any function
*/
