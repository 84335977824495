import { FC } from 'react'
import Button from 'components/base/forms/Button'
import Strings from 'project/localisation'
import classNames from 'classnames' // we need this to make JSX compile

type CardCarouselType = {
  value: number
  setValue: (v: number) => void
  className?: string
}

const CardCarousel: FC<CardCarouselType> = ({
  children,
  className,
  setValue,
  value = 0,
}) => {
  return (
    <div className={classNames('position-relative', className)}>
      {children[value]}
      <div className='position-absolute end-0 p-2 bottom-0'>
        {children.map((v, i) => (
          <Button
            key={i}
            aria-label={`Page ${i + 1}`}
            type='button'
            onClick={() => setValue(i)}
            theme='text'
            className={classNames('btn-carousel me-2', {
              'btn-carousel--active': value === i,
            })}
          />
        ))}
      </div>
    </div>
  )
}

export default CardCarousel
