import React, { FC, FormEvent, useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'libphonenumber-js'
import {
  useGetPhonesQuery,
  useUpdatePhoneMutation,
} from 'common/services/usePhone'
import { toast } from 'components/Toast'
import { useFlags } from 'flagsmith/react'
import { PhoneDetails } from 'common/types/responses'
import Card from 'components/base/Card'
import Button from 'components/base/forms/Button'
import ModalDefault, { ModalTitle } from 'components/ModalDefault'
import { ErrorMessage } from 'components/Messages'
import InputGroup from 'components/base/forms/InputGroup'
import PhoneInput from 'components/PhoneInput'
import { safeParseEventValue } from 'common/utils/safeParseEventValue'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { Format } from 'common/utils'
import { Project } from 'common/project'

type AddressCardProps = {
  className?: string
}

const AddressCard: React.FC<AddressCardProps> = ({ className }) => {
  const { subscriptionDetail } = useDefaultSubscription()
  const hasAddress = !!subscriptionDetail?.address?.postal_code
  return (
    <>
      <div className={className}>
        <Card
          footer={
            hasAddress && (
              <div className='flex-row align-items-center'>
                <div className='flex-fill text-sm'>
                  <div className='text-bold ms-2'>Forwarding address</div>
                  <div className=' ms-2'>
                    {Format.address(subscriptionDetail.address)}
                  </div>
                </div>
              </div>
            )
          }
          footerClassName='text-white p-5 bg-tertiary'
        >
          <div className='flex-fill'>
            <h1>Your Business Address</h1>
            <div className='mt-2'>
              {subscriptionDetail?.company?.legal_name ||
                subscriptionDetail?.company?.trading_name}
              <h3 className='fw-bold'>{Project.address}</h3>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default AddressCard
