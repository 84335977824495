import { FC, ReactNode } from 'react'
import Card from 'components/base/Card'
import Button from 'components/base/forms/Button'
import Link from 'next/link'
import cn from 'classnames' // we need this to make JSX compile

type TideCardType = {
  title: ReactNode
  logo: string
  image: string
  id: string
  className?: string
  style?: object
}

const FeaturedOfferCard: FC<TideCardType> = ({
  className,
  id,
  image,
  logo,
  style,
  title,
}) => {
  return (
    <Card padding={0} className={cn(className, 'featured-offer-card')}>
      <div className='flex-row'>
        <div className='flex-fill p-5'>
          <Button
            size='xs'
            theme='lightOutlineThin'
            className='btn-tide mb-2 py-1 cursor-default'
          >
            Members
          </Button>
          <h3 className='mb-2 lh-1 text-semibold text-white mt-2'>
            <>{title}</>
          </h3>
          <div className={'text-left'}>
            <img src={logo} className={'ad-brand'} />
          </div>
          <div className='flex-row mt-4'>
            <Link href={`/marketplace/${id}/`}>
              <Button className='px-4'>Learn More</Button>
            </Link>
          </div>
        </div>
        <img className='character' src={image} />
      </div>
    </Card>
  )
}

export default FeaturedOfferCard
