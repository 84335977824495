import { FC } from 'react'
import 'project/polyfill'
import { useRouter } from 'next/router'

import { useUser } from 'common/services/useUser'
import { NextPageWithLayout } from '../types/nextPageWithLayout'
import LoginPage from './auth/login'
import { defaultLayout } from './_app'
import Head from 'next/head'
import SubscriptionInfoCard from 'components/cards/SubscriptionInfoCard'
import AccountStatusCard from 'components/cards/AccountStatusCard'
import OffersCard from 'components/cards/OffersCard'
import PageContainer from 'components/PageContainer'
import StatementsCard from 'components/StatementsCard'
import PhoneCard from 'components/cards/PhoneCard' // we need this to make JSX compile
import { Project } from 'common/project'
import { ErrorMessage } from 'components/Messages'
import { useGetCompaniesQuery } from 'common/services/useCompany'
import { useFlags } from 'flagsmith/react' // we need this to make JSX compile
import AddressCard from 'components/cards/AddressCard'

export type HomePageType = {}

type DashboardType = {}

const Dashboard: FC<DashboardType> = ({}) => {
  const router = useRouter()
  const { data: companies } = useGetCompaniesQuery({})
  const { feature_kyc, feature_marketplace } = useFlags([
    'feature_marketplace',
    'feature_kyc',
  ])
  if (router.query.error) {
    return (
      <main className='container-fluid'>
        <Head>
          <title>{Project.siteName} | Dashboard</title>
        </Head>

        {!!router.query.error && (
          <ErrorMessage className='text-center py-3'>
            You do not have access to the requested page, please check you are
            logged in with the correct account.
          </ErrorMessage>
        )}
      </main>
    )
  }

  return (
    <main className='container-fluid'>
      <Head>
        <title>{Project.siteName} | Dashboard</title>
      </Head>
      <h1 className='d-block mb-4 mt-6'>Welcome back</h1>

      <div className='row'>
        <AddressCard className='col-xxl-3 col-xl-4 mb-4 col-lg-6 col-md-12' />
        {feature_kyc.enabled && (
          <AccountStatusCard className='col-xxl-3 col-xl-4 mb-4 col-lg-6 col-md-12' />
        )}
        <SubscriptionInfoCard
          className='col-xxl-3 col-xl-4 mb-4 col-lg-6 col-md-12'
          theme='condensed'
        />
        {companies?.map((v) => (
          <PhoneCard
            key={v.id}
            company_id={v.id}
            className='col-xxl-3 col-xl-4 mb-4 col-lg-6 col-md-12'
          />
        ))}
        <StatementsCard className='col-xxl-3 col-xl-4 mb-4 col-lg-6 col-md-12' />
        {feature_marketplace.enabled && (
          <div className='col-xxl-4 col-xl- mb-4 col-lg-6 col-md-12'>
            <OffersCard />
          </div>
        )}
      </div>
    </main>
  )
}

const HomePage: NextPageWithLayout<HomePageType> = ({}) => {
  const { data } = useUser({})
  const router = useRouter()
  if (!data) {
    return <LoginPage />
  } else {
    if (router.query.redirect) {
      router.replace(`${router.query.redirect}`)
      return null
    }
    return <Dashboard />
  }
}

HomePage.getLayout = (page, pageProps, user) => {
  return user ? defaultLayout(page) : <PageContainer>{page}</PageContainer>
}
export default HomePage
