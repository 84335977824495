import React, { FC } from 'react'
import Card from 'components/base/Card'
import Icon from 'components/Icon'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { kycNeedsVerifying } from 'common/services/useKycStatus'
import Link from 'next/link' // we need this to make JSX compile

type AccountStatusCardType = {
  className?: string
}

const AccountStatusCard: FC<AccountStatusCardType> = ({ className }) => {
  const { subscriptionDetail } = useDefaultSubscription()
  const kyc = subscriptionDetail?.kyc_status
  const needsVerify = kycNeedsVerifying(subscriptionDetail)
  const isPartnerCustomer = subscriptionDetail?.is_partner_customer

  if (isPartnerCustomer) {
    return (
      <div className={className}>
        <Card>
          <div className='flex-fill'>
            <h1>Account Status</h1>
            <div className='flex-row align-items-center'>
              <Icon name='check-circle' fill='success' />
              <h3 className='mb-0 ms-1 text-success'>Verified</h3>
            </div>
            <span className='my-2 text-xs d-block pb-3'>
              Your account has been verified by your business banking provider.
            </span>
          </div>
        </Card>
      </div>
    )
  }
  if (!needsVerify || !!subscriptionDetail?.is_partner_customer) return null
  return (
    <div className={className}>
      <Card>
        <div className='flex-fill'>
          <h1>Account Status</h1>
          <div className='flex-row align-items-center'>
            <Icon fill='danger' name='error' />
            <h3 className='mb-0 ms-1 text-danger'>Not Verified</h3>
          </div>
          <span className='my-2 text-xs d-block pb-3'>
            One or more of the officers associated with your company needs to
            provide proof of identity.
          </span>
        </div>
        {kyc?.kyc_link ? (
          <a
            href={kyc?.kyc_link}
            target='_blank'
            className='btn btn-primary'
            rel='noreferrer'
          >
            Verify
          </a>
        ) : (
          <Link
            href={'/business/owners'}
            className='btn btn-primary'
            rel='noreferrer'
          >
            Verify
          </Link>
        )}
      </Card>
    </div>
  )
}
export default AccountStatusCard
