import React from 'react'
import { Format } from 'common/utils'
import { BillingPeriod } from 'common/types/responses'
import Button from 'components/base/forms/Button'
import Card from 'components/base/Card'
import { usePaymentWarning } from 'common/hooks/usePaymentWarning'
import PaymentWarning from './PaymentWarning'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { useGetManageSubscriptionQuery } from 'common/services/useManageSubscription'
import dayjs from 'dayjs'
import cookies from 'js-cookie'
import clearCBCookies from 'project/clearCBCookies'
type SubscriptionInfoCardProps = {
  theme?: 'default' | 'condensed'
  className?: string
}

const SubscriptionInfoCard: React.FC<SubscriptionInfoCardProps> = ({
  className,
  theme = 'default',
}) => {
  const { defaultSubscriptionId, hasPermission, subscriptionDetail } =
    useDefaultSubscription()
  const { data: manageSubscriptionUrl, refetch } =
    useGetManageSubscriptionQuery(
      {
        id: subscriptionDetail?.company_id,
      },
      {
        skip: !subscriptionDetail?.company_id,
      },
    )
  const handleManageSubscription = () => {
    refetch()
    clearCBCookies()
    window.open(manageSubscriptionUrl?.access_url || '', '_blank')
  }
  const { paymentWarning } = usePaymentWarning()
  const isPartnerCustomer =
    subscriptionDetail?.is_partner_customer || !hasPermission('MANAGE_BILLING')
  if (isPartnerCustomer) {
    return null
  }
  if (theme === 'condensed') {
    return (
      <div className={className}>
        <Card
          footer={paymentWarning && <PaymentWarning />}
          footerClassName='text-white p-5 bg-danger-light'
        >
          <div className='flex-fill'>
            <h1>{subscriptionDetail?.name}</h1>
            <div className='text-tertiary no-wrap flex-row align-items-center'>
              <h3 className='m-0 me-1 text-tertiary text-nowrap text-bold'>
                {Format.money((subscriptionDetail?.price || 0) * 100)}
              </h3>
              {subscriptionDetail?.billing_period === BillingPeriod.ANNUAL
                ? 'Billed Yearly'
                : 'Billed Monthly'}
            </div>{' '}
            {subscriptionDetail?.next_payment_date && (
              <div className='mt-2'>
                Next payment due:{' '}
                <strong>
                  {dayjs(subscriptionDetail.next_payment_date * 1000).format(
                    'Do MMM YYYY',
                  )}
                </strong>
              </div>
            )}
          </div>

          {!paymentWarning && (
            <Button theme='darkOutline' onClick={handleManageSubscription}>
              Manage
            </Button>
          )}
        </Card>
      </div>
    )
  }
  return (
    <Card
      padding={0}
      className='overflow-hidden'
      footerClassName='text-white p-5 bg-danger-light'
      footer={paymentWarning && <PaymentWarning />}
    >
      <div className='row flex-fill'>
        <div className='col-md-7 h-100'>
          <div className='px-4 pb-4 h-100 d-flex flex justify-content-between flex-column'>
            <div className='flex-1 mt-4 mt-md-10'>
              <span className='text-muted text-small'>Your Plan</span>
              <h1 className='lh-1 mb-0 mt-2'>{subscriptionDetail?.name}</h1>
              <div className='mt-2 d-flex flex-fill align-items-center'>
                <div className='text-tertiary no-wrap flex-row align-items-center'>
                  <h3 className='m-0 me-1 text-tertiary text-nowrap text-bold'>
                    {Format.money((subscriptionDetail?.price || 0) * 100)}
                  </h3>
                  {subscriptionDetail?.billing_period === BillingPeriod.ANNUAL
                    ? 'Billed Yearly'
                    : 'Billed Monthly'}
                </div>
              </div>
            </div>
            {!paymentWarning && (
              <div className='flex-row mt-2'>
                <Button theme='darkOutline' onClick={handleManageSubscription}>
                  Manage
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className='d-none d-md-flex col-md-5 d-sm-none d-md-block'>
          <img
            className='my-1'
            src='/images/subscription-card.png'
            height={205}
          />
        </div>
      </div>
    </Card>
  )
}

export default SubscriptionInfoCard
