import React, { FC } from 'react'
import Card from './base/Card'
import Icon from './Icon'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { kycNeedsVerifying } from 'common/services/useKycStatus'
import Link from 'next/link'
import dayjs from 'dayjs' // we need this to make JSX compile

type AccountStatusCardType = {
  className?: string
}

const AccountStatusCard: FC<AccountStatusCardType> = ({ className }) => {
  const { subscriptionDetail } = useDefaultSubscription()
  const accounts = subscriptionDetail?.company?.company_accounts
  if (
    !accounts?.accounts_next_due_date &&
    !accounts?.confirmation_statement_next_due_date
  )
    return null
  return (
    <div className={className}>
      <Card>
        <div className='flex-fill'>
          <h1>Company Filings</h1>
          {!!accounts.accounts_next_due_date && (
            <>
              <h3 className='mb-0 fw-bold'>Accounts</h3>
              <div className='fs-sm'>
                {!!accounts.accounts_last_made_up_date && (
                  <div>
                    Next accounts made up to{' '}
                    <strong>
                      {dayjs(accounts.accounts_last_made_up_date).format(
                        'DD MMMM YYYY',
                      )}
                    </strong>{' '}
                  </div>
                )}
                Due by{' '}
                <strong>
                  {dayjs(accounts.accounts_next_due_date).format(
                    'DD MMMM YYYY',
                  )}
                </strong>
              </div>
            </>
          )}
          {!!accounts.confirmation_statement_next_due_date && (
            <>
              <h3 className='mb-0 fw-bold mt-2'>Confirmation Statement</h3>
              <div className='fs-sm'>
                {!!accounts.confirmation_statement_last_made_up_date && (
                  <div>
                    Confirmation Statement Date{' '}
                    <strong>
                      {dayjs(
                        accounts.confirmation_statement_last_made_up_date,
                      ).format('DD MMMM YYYY')}
                    </strong>{' '}
                  </div>
                )}
                Next statement to be filed by{' '}
                <strong>
                  {dayjs(accounts.confirmation_statement_next_due_date).format(
                    'DD MMMM YYYY',
                  )}
                </strong>
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  )
}
export default AccountStatusCard
