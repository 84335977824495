import React, { FC, useState } from 'react'
import FeaturedOfferCard from './FeaturedOfferCard'
import CardCarousel from './CardCarousel'

type OffersCardType = {}

const OffersCard: FC<OffersCardType> = ({}) => {
  const [value, setValue] = useState(0)

  return (
    <CardCarousel
      className='offer-cards minh-100 shadow-2'
      setValue={setValue}
      value={value}
    >
      <FeaturedOfferCard
        image={'/images/tide-team.png'}
        logo={'/images/tide.svg'}
        id={`efbbc8c9-e80d-4bc5-bdb3-1957fcb73f79`}
        title={
          <>
            <span className='tide-pink'>£75 cashback</span> when you open a
            business bank account for free
          </>
        }
      />
      <FeaturedOfferCard
        className={'crisp'}
        image={'/images/crisp-hero.png'}
        logo={'/images/crisp-brand_white.svg'}
        id={`efbbc8c9-e80d-4bc5-bdb3-1957fcb73f79`}
        title={
          <>
            <strong className='bold'>20% off</strong> plus a bonus 30 day free
            trial
          </>
        }
      />
    </CardCarousel>
  )
}

export default OffersCard
