import InputPhone from 'react-phone-input-2'
import { FC, FocusEventHandler, useState } from 'react'
import cn from 'classnames'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { InputProps } from './base/forms/Input'
type PhoneInputType = InputProps & { onEnterKeyPress?: () => void }
const PhoneInput: FC<PhoneInputType> = ({ ...props }) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const focusHandler: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(true)
    props.onFocus && props.onFocus(e)
  }
  const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    setShouldValidate(true)
    props.onBlur && props.onBlur(e)
  }
  const isValidNumber =
    !props.value || props.value?.length <= 3 || isValidPhoneNumber(props.value)
  return (
    <div
      className={cn(
        {
          focused: isFocused,
          invalid:
            (shouldValidate || props.touched) &&
            (props.isValid === false || !isValidNumber),
        },
        props.className,
      )}
    >
      <InputPhone
        onFocus={focusHandler}
        onEnterKeyPress={props.onEnterKeyPress}
        inputProps={{
          className: 'form-control',
          id: props.id,
          name: props.name,
        }}
        onBlur={blur}
        country='gb'
        onChange={(v, e, d, formattedValue) => {
          const value = formattedValue.replace(/ /g, '') as any
          props?.onChange?.(value)
        }}
        preferredCountries={['gb']}
        specialLabel=''
        value={props.value}
        data-test={props['data-test']}
      />
    </div>
  )
}

export default PhoneInput
