import { FC } from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
import { Project } from 'common/project'

export type PageContainerProps = {
  seoProps?: NextSeoProps
}
export const PageContainer: FC<PageContainerProps> = ({
  children,
  seoProps,
  ...rest
}) => {
  return (
    <>
      <NextSeo title={`${Project.siteName} | Portal`} {...seoProps} />
      {children}
    </>
  )
}

PageContainer.displayName = 'PageContainer'
export default PageContainer
